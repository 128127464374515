<template>
	<div class="sj_container sj_header">
		<router-link to="/">
			<div class="header_logo"></div>
		</router-link>
		<div class="header_nav hidden-md-and-down">
			<div class="navs">
				<div class="nav_item" :class="{active_this: activeIndex===index}" v-for="(nav,index) in navList"
					:key="index" @click="jump(index)">
					{{nav}}
					<span class="nav_item_bar"></span>
				</div>
			</div>
		</div>
		<div class="header_right hidden-md-and-down">
			<!--<span>公司资质</span>-->
			<span><a style="color: inherit" href="http://zhike.samjan.com" target="_blank">尚哲智课</a></span>
		</div>
		<!--mobile-->
		<a href="javascript:;" class="hidden-lg-and-up header_mobile" @click="handleCollapse">
			<i class="el-icon-menu"></i>
		</a>
		<div class="hidden-lg-and-up mobile_navs" :class="{'in':drawer}">
			<div class="nav_item" :class="{active_this: activeIndex===key}" v-for="(val,key) in navList" :key="key"
				@click="jump(index)">
				{{val}}
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		name: "Header",
		props: {
			active: {
				type: [Number, String],
				default: 0
			}
		},
		data() {
			return {
				drawer: false, //drawer
				activeIndex: 0, // 当前激活的导航索引
				navList: ["人工智能", "智慧医教", "智慧教室", "智慧护理", "联系我们"]
			}
		},
		created() {
			this.activeIndex = this.active
		},
		methods: {
			jump(index) {
				console.log('header_____', index)
				this.$router.push({
					name: 'home',
					params: {
						id: index
					}
				})
			},
			//mobile菜单
			handleCollapse() {
				this.drawer = !this.drawer
			},
		}
	};
</script>

<style lang="scss" scoped>
	.sj_header {
		height: 100%;
		display: flex;
		align-items: center;

		.header_logo {
			min-width: 400px;
			height: 60px;
			background-image: url("../assets/sj_logo_blue.png");
			background-repeat: no-repeat;
			background-position: center left;
			background-size: auto 30px;
		}

		.header_nav {
			flex: 1;

			.navs {
				@extend .flex;

				.nav_item {
					line-height: 60px;
					padding: 0 20px;
					cursor: pointer;
					position: relative;

					.nav_item_bar {
						height: 4px;
						background: #3981F5;
						opacity: 0;
						position: absolute;
						bottom: 0;
						left: 20px;
						right: 20px;
						transition: all 0.5s;
					}

					&.active_this {
						color: #3981F5;

						.nav_item_bar {
							opacity: 1;
						}
					}
				}
			}
		}

		.header_right {
			font-size: $fontsize_sm;

			span {
				color: rgba(0, 0, 0, 0.6);
				margin-left: 20px;
			}
		}

		.header_mobile {
			padding: 20px;
			position: absolute;
			top: 0;
			right: 0;

			.el-icon-menu {
				color: #3981F5;
				font-size: 20px;
			}
		}

		.mobile_navs {
			display: none;
			padding: 20px;
			height: 1px;
			background: rgba(255, 255, 255, 1);
			box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);
			transition: all 0.5s ease-in;
			position: fixed;
			top: 58px;
			left: 0;
			right: 0;

			.nav_item {
				line-height: 40px;
				cursor: pointer;

				&.active_this {
					color: #3981F5;
				}
			}

			&.in {
				display: block;
				height: auto;
			}
		}
	}
</style>