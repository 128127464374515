<template>
  <transition name="el-fade-in-linear">
    <el-container v-show="pageShow">
      <el-header class="sj_navbar_fixed">
        <Header :active="1"></Header>
      </el-header>
      <el-main class="sj_main">
        <div class="sj_container">
          <div class="sj_breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>护理实训基地</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <el-row :gutter="50">
            <el-col :md="24" :lg="12">
              <el-image class="sj_img" :src="img" @load="handleImg" @error="handleError">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
                <div slot="error" class="image_error">图片加载失败</div>
              </el-image>
            </el-col>
            <el-col :md="24" :lg="12">
              <div class="sj_content">
                <h2>护理实训基地</h2>
                <div class="sj_line"></div>
                <p>针对护理实训基地建设特点，对基础医学实训区、功能区和办公场地进行整体规划设计或者原环境的智能化升级改造。设计师现场测量，3D建模。方案设计将融合教学模式、教学内涵、教学技术和教学环境等四个方面要素，建立护理实训基地教学大数据智能教学平台，面向临床教学、培训和考核全过程构建基于课程知识图谱的形成性评价体系。</p>
                <p>方案包含基础护理实训室、健康评估实训室、内科护理实训室、外科护理实训室、产科护理实训室、妇科护理实训室、儿科护理实训室、五官科护理实训室、急救护理实训室、ICU 护理实训室、手术室护理实训室、形体训练房、智慧教室等场地设计。</p>
                <p>方案不仅仅提供了训练所需的模型人、操作台、网络、智能黑板（电脑投影仪）、门禁、智能门牌等基础训练和信息化设施，还运用人工智能、大数据、云计算、物联网、互联网、高级模拟人等先进技术，支持开展在线学习、实训室开放预约、在线训仿真实训室、同伴互评、边看边练、投屏示教等教学模式的开展，此外更提供护理相关课程的O2O立体讲义、学习资源和教学文化。</p>
                <p>支撑开展学生技能训练及社会培训工作，可承担执业医师、执业护士及相关专业的技能训练及考证工作，是医学职业院校首选的教学、示教、训练、考核、管理以及评价的智慧型临床技能中心一体化方案提供者。</p>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-main>
      <el-footer>
        <Footer></Footer>
      </el-footer>
    </el-container>
  </transition>
</template>

<script>
  import Header from '../../components/Header'
  import Footer from '../../components/Footer'

  export default {
    components: {
      Header,
      Footer
    },
    data(){
      return{
        pageShow:false,
        img:require("../../assets/medical/medical_6.jpg")
      }
    },
    methods:{
      handleImg() {
        this.pageShow = true;
      },
      handleError(){
        this.pageShow = true;
      }
    }
  }
</script>

<style scoped lang="scss">
  .sj_content{
    div,p{
      font-size: $fontsize_base;
      color: #6A7483;
    }
    p{
      margin: 40px 0;
      text-align: justify;
    }
    dl{
      float: left;
      margin-right: 50px;
      dd{
        padding: 4px 0;
      }
    }
  }
  .sj_line{
    margin-top: 20px;
  }
</style>
