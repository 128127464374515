<template>
    <transition name="el-fade-in-linear">
        <el-container v-show="pageShow">
            <el-header class="sj_navbar_fixed">
                <Header :active="2"></Header>
            </el-header>
            <el-main class="sj_main">
                <div class="sj_container">
                    <div class="sj_breadcrumb">
                        <el-breadcrumb separator-class="el-icon-arrow-right">
                            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                            <el-breadcrumb-item>工业机器人智慧实训基地</el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                    <el-row :gutter="50">
                        <el-col :md="24" :lg="12">
                            <img class="sj_img" src="../../assets/class/class_6.jpg"/>
                        </el-col>
                        <el-col :md="24" :lg="12">
                            <div class="sj_content">
                                <h2>工业机器人智慧实训基地</h2>
                                <div class="sj_line"></div>
                                <p>尚哲工业机器人智慧实训基地解决方案，结合了职业学校的专业教学特点，配套工业机器人工作站、工业机器人焊接工作站等各类典型应用工作站。使学生掌握工业机器人的基本知识、点位示教、基本运动指令、IO控制应用、曲面轨迹运动编程与示教、斜面位置搬运装配编程与示教、工件装配任务编程与示教、工件视觉检测与编程、触摸屏组态编程、工业机器人各种行业应用等知识与技能。支持互动教学与O2O立体教学模式，配套教学大数据系统，形成教师画像、学生画像，并提供各类数据分析视图，帮助教学管理者及时了解教学过程质量数据，利于教学教研的工作开展</p>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="sj_content_title">
                        <h2 class="title">更多类型</h2>
                        <div class="subtitle"><div class="sj_line"></div></div>
                    </div>
                    <el-row :gutter="30">
                        <el-col :xs="24" :md="12" :lg="8" v-for="(item,index) in listClass" :key="index">
                            <div class="sj_content_more" @click="jump(item.router)">
                                <el-image class="sj_img" :src="item.src" @load="handleImg" @error="handleError">
                                    <div slot="placeholder" class="image-slot">
                                        加载中<span class="dot">...</span>
                                    </div>
                                    <div slot="error" class="image_error">图片加载失败</div>
                                </el-image>
                                <div class="title">{{item.name}}</div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-main>
            <el-footer>
                <Footer></Footer>
            </el-footer>
        </el-container>
    </transition>
</template>

<script>
  import Header from "../../components/Header";
  import Footer from "../../components/Footer";

  export default {
    components: {
      Header,
      Footer
    },
    data() {
      return {
        type: "",
        pageShow: false,
        listClass:[
          {src:require('../../assets/class/class_sm_7.jpg'),name:'标准智慧教室',router:'c7'}
          ,{src:require('../../assets/class/class_sm_1.jpg'),name:'TBL智慧教室',router:'c1'}
          ,{src:require('../../assets/class/class_sm_2.jpg'),name:'PBL智慧教室',router:'c2'}
          ,{src:require('../../assets/class/class_sm_3.jpg'),name:'微格智慧教室',router:'c3'}
          ,{src:require('../../assets/class/class_sm_4.jpg'),name:'电教智慧教室',router:'c4'}
          ,{src:require('../../assets/class/class_sm_5.jpg'),name:'阶梯智慧教室',router:'c5'}
        ]
      };
    },
    mounted() {

    },
    methods:{
      //跳转页面
      jump(name) {
        this.$nextTick(function () {
          this.$router.push({ name: name })
        })
      },
      handleImg() {
        this.pageShow = true;
      },
      handleError(){
        this.pageShow = true;
      }
    }
  };
</script>

<style scoped lang="scss">
    .sj_line {
        margin-top: 20px;
    }
    .sj_content_more{
        margin-bottom: 30px;
        cursor: pointer;
        .title{
            line-height: 50px;
            background: #376ACB;
            color: #ffffff;
            font-size: $fontsize_base;
            text-indent: 20px;
        }
    }
</style>
