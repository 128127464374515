import Vue from "vue";
import ElementUI from "element-ui";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/display.css";
import App from "./App.vue";
import router from "./router/router";
import store from "./store";
import api from "./http/api";
import http from "./http/http";
import "./registerServiceWorker";
import "babel-polyfill";
import Es6Promise from "es6-promise";

Vue.use(ElementUI);

// axios 拦截器
import "./http/axios";

require("es6-promise").polyfill();
Es6Promise.polyfill();

//全局注册
Vue.prototype.$api = api;
Vue.prototype.$ajax = http;
Vue.config.productionTip = false;

// router.beforeEach((to, from, next) => {
//   console.log(to)
//   console.log(from)
//   if(to.name.indexOf('home')<0){
//     // chrome
//     document.body.scrollTop = 0
//     // firefox
//     document.documentElement.scrollTop = 0
//     // safari
//     window.pageYOffset = 0
//   }
//
//   next();
// });


new Vue({
  el: "#app",
  router,
  store,
  render: h => h(App)
});
