<template>
	<transition name="el-fade-in-linear">
		<el-container v-show="pageShow">
			<el-header class="sj_navbar_fixed">
				<Header :active="3"></Header>
			</el-header>
			<el-main class="sj_main">
				<div class="sj_container">
					<div class="sj_breadcrumb">
						<el-breadcrumb separator-class="el-icon-arrow-right">
							<el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
							<el-breadcrumb-item>移动护理信息系统</el-breadcrumb-item>
						</el-breadcrumb>
					</div>
					<el-row :gutter="50">
						<el-col :md="24" :lg="12">
							<img class="sj_img" src="../../assets/nursing/n_3.png" />
						</el-col>
						<el-col :md="24" :lg="12">
							<div class="sj_content">
								<h2>移动护理信息系统</h2>
								<div class="sj_line"></div>
								<p>以条码识别技术及结构化的表单为基础，利用大数据、云计算、物联网等科技的应用创新，助力护理创业数字化的升级改造，协助客户更智能的服务客户。</p>
								<p>在优化工作流程的同时规范护理操作，杜绝人工核对、手工填写误差，提高护理质量，患者安全，减少医患矛盾，为患者提供更安全、人性化的就医环境，实现临床业务的移动化、无纸化，从各个方面提高患者就医体验，获取居民的认可度，提升医院的竞争力。
								</p>
							</div>
						</el-col>
					</el-row>
					<div class="sj_content_title">
						<h2 class="title">特色优势</h2>
						<div class="subtitle">
							<div class="sj_line"></div>
						</div>
					</div>
					<div class="sj_content_img">
						<el-image :src="listImg.src1">
							<div slot="placeholder" class="image-slot">
								加载中<span class="dot">...</span>
							</div>
							<div slot="error" class="image_error">图片加载失败</div>
						</el-image>
					</div>
					<div class="sj_content_title">
						<h2 class="title">护理文书</h2>
						<div class="subtitle">
							<div class="sj_line"></div>
						</div>
					</div>
					<div class="sj_content_img">
						<el-image :src="listImg.src2">
							<div slot="placeholder" class="image-slot">
								加载中<span class="dot">...</span>
							</div>
							<div slot="error" class="image_error">图片加载失败</div>
						</el-image>
						<el-image :src="listImg.src3">
							<div slot="placeholder" class="image-slot">
								加载中<span class="dot">...</span>
							</div>
							<div slot="error" class="image_error">图片加载失败</div>
						</el-image>
					</div>
					<div class="sj_content_title">
						<h2 class="title">移动护士工作站</h2>
						<div class="subtitle">
							<div class="sj_line"></div>
						</div>
					</div>
					<div class="sj_content_img">
						<el-image :src="listImg.src4" @load="handleImg" @error="handleError">
							<div slot="placeholder" class="image-slot">
								加载中<span class="dot">...</span>
							</div>
							<div slot="error" class="image_error">图片加载失败</div>
						</el-image>
					</div>
				</div>
			</el-main>
			<el-footer>
				<Footer></Footer>
			</el-footer>
		</el-container>
	</transition>
</template>

<script>
	import Header from "../../components/Header";
	import Footer from "../../components/Footer";

	export default {
		components: {
			Header,
			Footer
		},
		data() {
			return {
				pageShow: false,
				listImg: {
					src1: require('../../assets/nursing/n_3_1.png'),
					src2: require('../../assets/nursing/n_3_2.png'),
					src3: require('../../assets/nursing/n_3_3.png'),
					src4: require('../../assets/nursing/n_3_4.png')
				}
			};
		},
		methods: {
			handleImg() {
				this.pageShow = true;
			},
			handleError() {
				this.pageShow = true;
			}
		}
	};
</script>

<style scoped lang="scss">
	.sj_line {
		margin-top: 20px;
	}
</style>