<template>
  <transition name="el-fade-in-linear">
    <el-container v-show="pageShow">
      <el-header class="sj_navbar_fixed">
        <Header :active="1"></Header>
      </el-header>
      <el-main class="sj_main">
        <div class="sj_container">
          <div class="sj_breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>虚拟仿真模拟肺</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <el-row :gutter="50">
            <el-col :md="24" :lg="12">
              <el-image class="sj_img" :src="img" @load="handleImg" @error="handleError">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
                <div slot="error" class="image_error">图片加载失败</div>
              </el-image>
            </el-col>
            <el-col :md="24" :lg="12">
              <div class="sj_content">
                <h2>虚拟仿真模拟肺</h2>
                <div class="sj_line"></div>
                <p>● 仿生系统机箱储气量3.5L，通过设置呼吸力学参数可平滑的模拟不同年龄段(从新生儿到老年)、不同性别、不同体重患者的各种呼吸系统疾病模型。仿生系统可自动检测到参数调整并做出自动反应。</p>
                <p>● 机箱具有隐藏式把手及底部减震支脚，可独立于台车使用且携带方便。</p>
                <p>● 工作温度:-50°C~+100C，可以实现亚微秒级别的高精度响应。</p>
                <p>● 可精确控制直线移动距离，从而精确控制呼吸气量的大小，机构高速平稳运行及无噪运行。</p>
                <p>● 系统可连接临床各种型号的真实呼吸机、麻醉机，实现呼吸机触发和机械通气治疗。</p>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-main>
      <el-footer>
        <Footer></Footer>
      </el-footer>
    </el-container>
  </transition>
</template>

<script>
  import Header from '../../components/Header'
  import Footer from '../../components/Footer'

  export default {
    components: {
      Header,
      Footer
    },
    data(){
      return{
        pageShow:false,
        img:require("../../assets/medical/medical_666.png")
      }
    },
    methods:{
      handleImg() {
        this.pageShow = true;
      },
      handleError(){
        this.pageShow = true;
      }
    }
  }
</script>

<style scoped lang="scss">
  .sj_content{
    div,p{
      font-size: $fontsize_base;
      color: #6A7483;
    }
    p{
      margin: 40px 0;
      text-align: justify;
    }
    dl{
      float: left;
      margin-right: 50px;
      dd{
        padding: 4px 0;
      }
    }
  }
  .sj_line{
    margin-top: 20px;
  }
</style>
