<template>
  <transition name="el-fade-in-linear">
    <el-container v-show="pageShow">
      <el-header class="sj_navbar_fixed">
        <Header :active="1"></Header>
      </el-header>
      <el-main class="sj_main">
        <div class="sj_container">
          <div class="sj_breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>尚哲体格检查模拟人（教师机）</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <el-row :gutter="50">
            <el-col :md="24" :lg="12">
              <el-image class="sj_img" :src="img" @load="handleImg" @error="handleError">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
                <div slot="error" class="image_error">图片加载失败</div>
              </el-image>
            </el-col>
            <el-col :md="24" :lg="12">
              <div class="sj_content">
                <h2>尚哲体格检查模拟人（教师机）</h2>
                <div class="sj_line"></div>
                <p>● 具有教学示教和训练模式，可全面评估学习效果，能实现局域网和互联网登录，既满足现场教学又具备网上教学功能。</p>
                <p>● 系统所有教学内容均可自行新建。</p>
                <p>● 针对诊断学课程体系，满足头部查体和胸部、腹部“视、触、叩、听”的技能训练与教学。</p>
                <p>● 基于 HIFI 设备专用的声音解码单元与发声单元，将通过高保真设备采集自真实病人的心肺音完美呈现。</p>
                <p>● 软件可在平板、PC等智能终端打开，无需预先安装软件，实现模型与控制系统的无线连接和数据通讯。心肺听诊模型与腹部触诊由同一个控制软件控制。</p>
                <p>● 心肺音的听诊可以采用外观仿真的电子听诊器在模型上听诊，也可以通过外放进行全体教字。</p>
                <p>● 系统包括大量的教学素材，有文字、图片、视频、动画等，部分内容还配有心电图、心音图、超声心动图、DICOM 图像资料等，部分配有影像三维重建的解剖素材。</p>
                <p>● 教师软件可以对学生软件进行霸屏控制，在受控状态下，学生软件仅显示教师界面的内容解除控制后恢复正常学生机控制功能。</p>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-main>
      <el-footer>
        <Footer></Footer>
      </el-footer>
    </el-container>
  </transition>
</template>

<script>
  import Header from '../../components/Header'
  import Footer from '../../components/Footer'

  export default {
    components: {
      Header,
      Footer
    },
    data(){
      return{
        pageShow:false,
        img:require("../../assets/medical/medical_888.png")
      }
    },
    methods:{
      handleImg() {
        this.pageShow = true;
      },
      handleError(){
        this.pageShow = true;
      }
    }
  }
</script>

<style scoped lang="scss">
  .sj_content{
    div,p{
      font-size: $fontsize_base;
      color: #6A7483;
    }
    p{
      margin: 40px 0;
      text-align: justify;
    }
    dl{
      float: left;
      margin-right: 50px;
      dd{
        padding: 4px 0;
      }
    }
  }
  .sj_line{
    margin-top: 20px;
  }
</style>
