<template>
    <transition name="el-fade-in-linear">
        <el-container v-show="pageShow">
            <el-header class="sj_navbar_fixed">
                <Header :active="3"></Header>
            </el-header>
            <el-main class="sj_main">
                <div class="sj_container">
                    <div class="sj_breadcrumb">
                        <el-breadcrumb separator-class="el-icon-arrow-right">
                            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                            <el-breadcrumb-item>物联网+护理</el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                    <div class="sj_content_title">
                        <h2 class="title">基于华为物联网融合方案构建医疗领域万物互联</h2>
                        <div class="subtitle"><div class="sj_line"></div></div>
                    </div>
                    <div class="sj_content_img">
                        <el-image :src="listImg.src1">
                            <div slot="placeholder" class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                            <div slot="error" class="image_error">图片加载失败</div>
                        </el-image>
                    </div>
                    <div class="sj_content_title">
                        <h2 class="title">生命体征监测</h2>
                        <div class="subtitle"><div class="sj_line"></div></div>
                    </div>
                    <div class="sj_content_img">
                        <el-image :src="listImg.src2">
                            <div slot="placeholder" class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                            <div slot="error" class="image_error">图片加载失败</div>
                        </el-image>
                    </div>
                    <div class="sj_content_title">
                        <h2 class="title">输液监控</h2>
                        <div class="subtitle"><div class="sj_line"></div></div>
                    </div>
                    <div class="sj_content_img">
                        <el-image :src="listImg.src3">
                            <div slot="placeholder" class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                            <div slot="error" class="image_error">图片加载失败</div>
                        </el-image>
                    </div>
                    <div class="sj_content_title">
                        <h2 class="title">婴儿防盗</h2>
                        <div class="subtitle"><div class="sj_line"></div></div>
                    </div>
                    <div class="sj_content_img">
                        <el-image :src="listImg.src4">
                            <div slot="placeholder" class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                            <div slot="error" class="image_error">图片加载失败</div>
                        </el-image>
                    </div>
                    <div class="sj_content_title">
                        <h2 class="title">手卫生依从性</h2>
                        <div class="subtitle"><div class="sj_line"></div></div>
                    </div>
                    <div class="sj_content_img">
                        <el-image :src="listImg.src5" @load="handleImg" @error="handleError">
                            <div slot="placeholder" class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                            <div slot="error" class="image_error">图片加载失败</div>
                        </el-image>
                    </div>
                </div>
            </el-main>
            <el-footer>
                <Footer></Footer>
            </el-footer>
        </el-container>
    </transition>
</template>

<script>
  import Header from "../../components/Header";
  import Footer from "../../components/Footer";

  export default {
    components: {
      Header,
      Footer
    },
    data() {
      return {
        pageShow:false,
        listImg:{
          src1:require('../../assets/nursing/n_4_1.png')
          ,src2:require('../../assets/nursing/n_4_2.png')
          ,src3:require('../../assets/nursing/n_4_3.png')
          ,src4:require('../../assets/nursing/n_4_4.png')
          ,src5:require('../../assets/nursing/n_4_5.png')
        }
      };
    },
    methods:{
      handleImg() {
        this.pageShow = true;
      },
      handleError(){
        this.pageShow = true;
      }
    }
  };
</script>

<style scoped lang="scss">
    .sj_line {
        margin-top: 20px;
    }
</style>
