<template>
  <el-container v-show="pageShow">
    <el-header class="sj_navbar" :class="{ header_fixed: navFixed === true }">
      <div class="sj_container sj_header">
        <div class="header_logo" @click="scrollToBar" @click.stop></div>
        <div class="header_nav hidden-md-and-down">
          <div class="navs">
            <div
              class="nav_item"
              :ref="index"
              :class="{ active: active === index }"
              v-for="(nav, index) in navList"
              :key="index"
              @click="scrollTo(index)"
            >
              {{ nav }}
              <span class="nav_item_bar"></span>
            </div>
          </div>
        </div>
        <div class="header_right hidden-md-and-down">
          <!--<span>公司资质</span>-->
          <!-- <span
            ><a
              style="color: inherit"
              href="http://zhike.samjan.com"
              target="_blank"
              >尚哲智课</a>
            </span> -->
          <!-- 医教 智课入口 -->
          <div class="nav_function">
            <div class="nav_fun_bar" @click="handleView(1)">
              <img src="../assets/yijiao.png" alt="" />
              <span class="logoTit">尚哲医教</span>
            </div>

            <div class="nav_fun_bar" @click="handleView(2)">
              <img src="../assets/zhike.png" alt="" />
              <span class="logoTit">尚哲智课</span>
            </div>
          </div>
        </div>
        <a
          href="javascript:;"
          class="hidden-lg-and-up header_mobile"
          @click="handleCollapse"
        >
          <i class="el-icon-menu"></i>
        </a>
        <div class="hidden-lg-and-up mobile_navs" :class="{ active: drawer }">
          <div
            class="nav_item"
            :ref="index"
            :class="{ active: active === index }"
            v-for="(nav, index) in navList"
            :key="index"
            @click="scrollTo(index)"
          >
            {{ nav }}
          </div>
        </div>
      </div>
    </el-header>
    <el-main>
      <div class="sj_carousel">
        <el-image :src="imgList.banner" fit="fill">
          <div slot="placeholder" class="image-slot">
            加载中<span class="dot">...</span>
          </div>
          <div slot="error" class="image_error">图片加载失败</div>
        </el-image>
        <div class="sj_carousel_desc">
          <img
            class="title"
            src="../assets/home/home_text.png"
            alt="尚哲人工智能"
          />
          <div class="sj_line"></div>
          <div class="text">
            实践“先进模式”和“智能技术” <br />驱动的质量提升
          </div>
        </div>
        <i class="hidden-xs-only sj_mouse"></i>
      </div>
      <div class="sj_container">
        <!--人工智能-->
        <div class="sj_target sj_card" id="target_1">
          <div class="card_header">
            <div class="title a"></div>
            <div class="subtitle">智能技术让行业专家的智力和体力无限延伸</div>
          </div>
          <div class="card_body">
            <el-row :gutter="20">
              <el-col :md="24" :lg="12">
                <el-image class="links" :src="imgList.sam" @click="jump('sam')">
                  <div slot="placeholder" class="image-slot">
                    图片加载中<span class="dot">...</span>
                  </div>
                  <div slot="error" class="image_error">图片加载失败</div>
                </el-image>
              </el-col>
              <el-col :md="24" :lg="12">
                <el-image class="links" :src="imgList.jan" @click="jump('jan')">
                  <div slot="placeholder" class="image-slot">
                    图片加载中<span class="dot">...</span>
                  </div>
                  <div slot="error" class="image_error">图片加载失败</div>
                </el-image>
              </el-col>
            </el-row>
            <el-row :gutter="50">
              <el-col :md="24" :lg="4">
                <el-image
                  class="img_laboratory"
                  :src="imgList.a1"
                  fit="scale-down"
                >
                  <div slot="placeholder" class="image-slot">
                    图片加载中<span class="dot">...</span>
                  </div>
                  <div slot="error" class="image_error">图片加载失败</div>
                </el-image>
                <img
                  class="btn_cooperation"
                  :src="imgList.btn"
                  alt="合作申请"
                  @click="jump('cooperation')"
                />
              </el-col>
              <el-col :md="24" :lg="20">
                <el-image :src="imgList.a2">
                  <div slot="placeholder" class="image-slot">
                    图片加载中<span class="dot">...</span>
                  </div>
                  <div slot="error" class="image_error">图片加载失败</div>
                </el-image>
              </el-col>
            </el-row>
          </div>
        </div>

        <!--智慧医教-->
        <div class="sj_target sj_card" id="target_2">
          <div class="card_header">
            <div class="title b"></div>
            <div class="subtitle">能出数的方案才是好方案</div>
          </div>
          <div class="card_body">
            <el-row>
              <el-col :lg="24">
                <div class="img_box">
                  <el-image
                    :src="imgList.b1"
                    @click="jump('m4')"
                    @load="handleImg"
                    @error="handleError"
                  >
                    <div slot="placeholder" class="image-slot">
                      图片加载中<span class="dot">...</span>
                    </div>
                    <div slot="error" class="image_error">图片加载失败</div>
                  </el-image>
                  <div class="box_desc_right hidden-xs-only">
                    <div class="flex up">
                      <a
                        class="link_up b1"
                        href="javascript:;"
                        @click="jump('m5')"
                      ></a>
                      <a
                        class="link_up b2"
                        href="javascript:;"
                        @click="jump('m6')"
                      ></a>
                    </div>
                    <div class="flex">
                      <a
                        class="link b3"
                        title="智慧型内科技能训练室"
                        href="javascript:;"
                        @click="jump('m7')"
                      ></a>
                      <a
                        class="link b4"
                        title="智慧型外科技能训练室"
                        href="javascript:;"
                        @click="jump('m8')"
                      ></a>
                      <a
                        class="link b5"
                        title="智慧型妇产儿技能训练室"
                        href="javascript:;"
                        @click="jump('m9')"
                      ></a>
                      <a
                        class="link b6"
                        title="智慧型急危重症技能训练室"
                        href="javascript:;"
                        @click="jump('m10')"
                      ></a>
                      <a
                        class="link b7"
                        title="智慧型护理技能训练室"
                        href="javascript:;"
                        @click="jump('m11')"
                      ></a>
                      <a
                        class="link b8"
                        title="智慧型老年照护技能训练室"
                        href="javascript:;"
                        @click="jump('m12')"
                      ></a>
                    </div>
                  </div>
                  <!--mobile-->
                  <div class="hidden-sm-and-up box_desc_right mobile">
                    <div class="flex up">
                      <a
                        class="link_up b1"
                        href="javascript:;"
                        @click="jump('m5')"
                      ></a>
                      <a
                        class="link_up b2"
                        href="javascript:;"
                        @click="jump('m6')"
                      ></a>
                    </div>
                    <div class="flex">
                      <a
                        class="link b3"
                        title="智慧型内科技能训练室"
                        href="javascript:;"
                        @click="jump('m7')"
                      ></a>
                      <a
                        class="link b4"
                        title="智慧型外科技能训练室"
                        href="javascript:;"
                        @click="jump('m8')"
                      ></a>
                      <a
                        class="link b5"
                        title="智慧型妇产儿技能训练室"
                        href="javascript:;"
                        @click="jump('m9')"
                      ></a>
                      <a
                        class="link b6"
                        title="智慧型急危重症技能训练室"
                        href="javascript:;"
                        @click="jump('m10')"
                      ></a>
                      <a
                        class="link b7"
                        title="智慧型护理技能训练室"
                        href="javascript:;"
                        @click="jump('m11')"
                      ></a>
                      <a
                        class="link b8"
                        title="智慧型老年照护技能训练室"
                        href="javascript:;"
                        @click="jump('m12')"
                      ></a>
                    </div>
                  </div>
                </div>
              </el-col>
            </el-row>

            <el-row :gutter="20">
              <el-col
                :sm="24"
                :md="12"
                :lg="6"
                v-for="(item, index) in listEdu"
                :key="index"
              >
                <el-image
                  class="links"
                  :src="item.src"
                  @click="jump(item.name)"
                >
                  <div slot="placeholder" class="image-slot">
                    图片加载中<span class="dot">...</span>
                  </div>
                  <div slot="error" class="image_error">图片加载失败</div>
                </el-image>
              </el-col>
            </el-row>
						
						<el-row :gutter="20">
						  <el-col
						    :sm="24"
						    :md="12"
						    :lg="8"
						    v-for="(item, index) in listEdu2"
						    :key="index"
						  >
						    <el-image
						      class="links"
									style="border: 1px solid #f7f8fa;"
						      :src="item.src"
						      @click="jump(item.name)"
						    >
						      <div slot="placeholder" class="image-slot">
						        图片加载中<span class="dot">...</span>
						      </div>
						      <div slot="error" class="image_error">图片加载失败</div>
						    </el-image>
						  </el-col>
						</el-row>
						
          </div>
        </div>

        <!--智慧教室-->
        <div class="sj_target sj_card" id="target_3">
          <div class="card_header">
            <div class="title c"></div>
            <div class="subtitle">有大数据的智慧教室才是4.0</div>
          </div>
          <div class="card_body">
            <el-row>
              <el-col :lg="24">
                <div class="img_box">
                  <el-image :src="imgList.c1" @click="jump('c7')">
                    <div slot="placeholder" class="image-slot">
                      图片加载中<span class="dot">...</span>
                    </div>
                    <div slot="error" class="image_error">图片加载失败</div>
                  </el-image>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col
                :sm="24"
                :md="12"
                :lg="8"
                v-for="(item, index) in listClass"
                :key="index"
              >
                <el-image
                  class="links"
                  :src="item.src"
                  @click="jump(item.name)"
                >
                  <div slot="placeholder" class="image-slot">
                    图片加载中<span class="dot">...</span>
                  </div>
                  <div slot="error" class="image_error">图片加载失败</div>
                </el-image>
              </el-col>
            </el-row>
          </div>
        </div>

        <!--智慧护理-->
        <div class="sj_target sj_card" id="target_4">
          <div class="card_header">
            <div class="title d"></div>
            <div class="subtitle">科技，让护理更轻松</div>
          </div>
          <div class="card_body">
            <el-row>
              <el-col :lg="24">
                <div class="img_box">
                  <el-image :src="imgList.d1" @click="jump('n5')">
                    <div slot="placeholder" class="image-slot">
                      图片加载中<span class="dot">...</span>
                    </div>
                    <div slot="error" class="image_error">图片加载失败</div>
                  </el-image>
                </div>
              </el-col>
            </el-row>
            <el-row :gutter="20">
              <el-col
                :sm="24"
                :md="12"
                v-for="(item, index) in listNursing"
                :key="index"
              >
                <div class="links flex" @click="jump(item.name)">
                  <el-image class="flex-item" :src="item.src">
                    <div slot="placeholder" class="image-slot">
                      图片加载中<span class="dot">...</span>
                    </div>
                    <div slot="error" class="image_error">图片加载失败</div>
                  </el-image>
                  <div class="flex-item">
                    <div class="subtitle" :class="`d${index + 1}`"></div>
                    <div class="subtext">{{ item.text }}</div>
                  </div>
                </div>
              </el-col>
            </el-row>
            <el-row>
              <el-col :lg="24">
                <div class="img_box">
                  <div class="version_item hidden-xs-only">
                    <el-image
                      :src="imgList.versionLogo"
                      class="imgLogo logo_bottom"
                    >
                      <div slot="placeholder">
                        图片加载中<span class="dot">...</span>
                      </div>
                      <div slot="error" class="image_error">图片加载失败</div>
                    </el-image>

                    <div class="version_info">
                      <p>尚哲医教</p>
                      <p>
                        尚哲医教，是一款面向医学教育的教学软件。整合了教学资源、课程日历、同伴互评、课堂互动、教室预约、训练报告等功能于一体，让医学生课前、课中、课后全流程地参与学习及评价，让学习更加高效、便捷。
                      </p>
                    </div>
                  </div>
									<div class="version_item-mobile hidden-sm-and-up">
										<div class="imgBox" align="center">
											<img :src="imgList.versionLogo" class="app_logo" alt="尚哲医教"  ><br/>
											<strong style="font-size: 3.2vw;">尚哲医教</strong>
										</div>
										一款面向医学教育的教学软件。整合了教学资源、课程日历、同伴互评、课堂互动、教室预约、训练报告等功能于一体，让医学生课前、课中、课后全流程地参与学习及评价，让学习更加高效、便捷。
									</div>
                  <el-image :src="imgList.version">
                    <div slot="placeholder" class="image-slot">
                      图片加载中<span class="dot">...</span>
                    </div>
                    <div slot="error" class="image_error">图片加载失败</div>
                  </el-image>
                </div>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>

      <!--联系我们-->
      <div class="sj_target sj_contact" id="target_5">
        <div class="sj_container sj_card">
          <!--<div class="card_header">-->
          <!--<div class="title e"></div>-->
          <!--</div>-->
          <div class="card_body">
            <el-row :gutter="20">
              <el-col :xs="24" :sm="12" :md="12">
                <el-image class="title" :src="imgList.contact">
                  <div slot="placeholder" class="image-slot">
                    加载中<span class="dot">...</span>
                  </div>
                  <div slot="error" class="image_error">图片加载失败</div>
                </el-image>
                <dl class="info">
                  <dt>南京尚哲智能科技有限公司</dt>
                  <dd>
                    地址：南京市建邺区云龙山路100号信安大厦B座1205、1206室
                  </dd>
                  <dd>电话：025-58612326</dd>
                  <dd>邮箱：hello@samjan.com</dd>
                  <dd>网址：www.samjan.com</dd>
                </dl>
              </el-col>
              <el-col :xs="24" :sm="12" :md="12">
                <el-image class="" :src="imgList.e1">
                  <div slot="placeholder" class="image-slot">
                    图片加载中<span class="dot">...</span>
                  </div>
                  <div slot="error" class="image_error">图片加载失败</div>
                </el-image>
              </el-col>
            </el-row>
          </div>
        </div>
      </div>
    </el-main>
    <el-footer>
      <Footer />
    </el-footer>
    <el-backtop>
      <img
        src="../assets/button/top.png"
        width="50"
        height="50"
        alt="返回顶部"
      />
    </el-backtop>
  </el-container>
</template>

<script>
import Footer from "@/components/Footer";

var loading;
export default {
  name: "home",
  components: {
    Footer,
  },
  data() {
    return {
      // netryName: true,
      pageShow: false,
      drawer: false, //drawer
      active: 0, // 当前激活的导航索引
      navList: ["人工智能", "智慧医教", "智慧教室", "智慧护理", "联系我们"],
      navFixed: false,
      imgList: {
        banner: require("../assets/home/home_banner.jpg"),
        sam: require("../assets/home/home_sam.png"),
        jan: require("../assets/home/home_jan.png"),
        btn: require("../assets/home/home_btn.png"),
        a1: require("../assets/home/home_a_1.png"),
        a2: require("../assets/home/home_a_2.png"),
        b1: require("../assets/home/home_b_1.jpg"),
        c1: require("../assets/home/home_c_1.jpg"),
        d1: require("../assets/home/home_d_1.jpg"),
        contact: require("../assets/home/home_e.png"),
        e1: require("../assets/home/home_e_1.png"),
        version: require("../assets/home/home_version.png"),
        versionLogo: require("../assets/home/home_version_logo.png"),
      },
      listEdu: [
        {
          src: require("../assets/home/home_b_2.png"),
          name: "m1",
        },
        {
          src: require("../assets/home/home_b_3.png"),
          name: "m2",
        },
        {
          src: require("../assets/home/home_b_4.png"),
          name: "m3",
        },
        {
          src: require("../assets/home/home_b_5.png"),
          name: "",
        },
      ],
			listEdu2: [
			  {
			    src: require("../assets/home/home_b_6.png"),
			    name: "m666",
			  },
			  {
			    src: require("../assets/home/home_b_7.png"),
			    name: "m777",
			  },
			  {
			    src: require("../assets/home/home_b_8.png"),
			    name: "m888",
			  }
			],
      listClass: [
        {
          src: require("../assets/home/home_c_2.png"),
          name: "c1",
        },
        {
          src: require("../assets/home/home_c_3.png"),
          name: "c2",
        },
        {
          src: require("../assets/home/home_c_4.png"),
          name: "c3",
        },
        {
          src: require("../assets/home/home_c_5.png"),
          name: "c4",
        },
        {
          src: require("../assets/home/home_c_6.png"),
          name: "c5",
        },
        {
          src: require("../assets/home/home_c_7.png"),
          name: "c6",
        },
      ],
      listNursing: [
        {
          src: require("../assets/home/home_d_2.png"),
          name: "n1",
          title: "智慧病房",
          text: "以患者为中心，\n落实“床旁服务观念”。",
        },
        {
          src: require("../assets/home/home_d_3.png"),
          name: "n2",
          title: "互联网+护理",
          text: "响应国家政策，为居民、医疗机构提供\n可靠便利的互联网平台。",
        },
        {
          src: require("../assets/home/home_d_4.png"),
          name: "n3",
          title: "移动护理信息系统",
          text: "全结构化的护理文书结合移动护士工作\n站，解决离患者最后1.5m的距离。",
        },
        {
          src: require("../assets/home/home_d_5.png"),
          name: "n4",
          title: "物联网+护理",
          text: "通过物联网基站、物联网平台实时收集\n各项数据，实时监控核心指标，有效提\n升护理质量。",
        },
      ],
    };
  },
  beforeCreate() {
    loading = this.$loading({
      lock: true,
      text: "努力加载中...",
      spinner: "el-icon-loading",
      background: "rgba(0, 0, 0, 1)",
    });
  },
  created() {
    window.addEventListener("scroll", this.onScroll);
  },
  mounted() {},
  methods: {
    //打开新标签
    handleView(type) {
      if (type == 1) {
        //医教
        window.open("https://yj.samjan.com", "_blank");
      } else if (type == 2) {
        //智课
        window.open(
          "https://zhike.samjan.com/platform/zhike/#/login",
          "_blank"
        );
      }
    },
    /**
     * 图片加载func
     */
    handleImg() {
      loading.close();
      this.pageShow = true;
      if (this.$route.params.id >= 0) {
        const navIndex = this.$route.params.id;
        this.active = navIndex;
        this.$nextTick(() => {
          this.scrollTo(navIndex);
        });
      }
    },
    handleError() {
      loading.close();
      this.pageShow = true;
    },
    //mobile菜单
    handleCollapse() {
      this.drawer = !this.drawer;
    },
    //跳转页面
    jump(name) {
      if (name.length === 0) {
        return;
      } else {
        window.removeEventListener("scroll", this.onScroll);
        this.$nextTick(function () {
          this.$router.push({
            name: name,
          });
        });
      }
    },
    // 滚动监听器
    onScroll() {
      // 获取所有锚点元素
      const navContents = document.querySelectorAll(".sj_target");
      // 所有锚点元素的 offsetTop
      const offsetTopArr = [];
      navContents.forEach((item) => {
        offsetTopArr.push(item.offsetTop);
      });
      // console.log(offsetTopArr);
      // 获取当前文档流的 scrollTop
      const scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // console.log('scrollTop:'+scrollTop)
      const boc = document.getElementsByClassName("logoTit")[0];
      const boc1 = document.getElementsByClassName("logoTit")[1];
      if (scrollTop > 100) {
        this.navFixed = true;
        boc.style.color = "#000000";
        boc1.style.color = "#000000";
        //   this.netryName = false;
      } else {
        this.navFixed = false;
        boc.style.color = "#ffffff";
        boc1.style.color = "#ffffff";
        //   this.netryName = true;
      }
      // 定义当前点亮的导航下标
      let navIndex = 0;
      for (let n = 0; n < offsetTopArr.length; n++) {
        if (scrollTop >= offsetTopArr[n] - 100) {
          navIndex = n;
        }
      }
      // console.log('navIndex:'+navIndex)
      this.active = navIndex;
    },
    //跳转顶部
    scrollToBar() {
      let step = 0;
      let interval = setInterval(() => {
        let scrollTop =
          window.pageYOffset ||
          document.documentElement.scrollTop ||
          document.body.scrollTop;
        if (scrollTop <= 0) {
          clearInterval(interval);
          return;
        }
        step += 10;
        document.body.scrollTop -= step;
        document.documentElement.scrollTop -= step;
      }, 20);
    },
    // 跳转到指定索引的元素
    scrollTo(index) {
      // offsetTop
      const targetOffsetTop =
        document.getElementById(`target_${index + 1}`).offsetTop - 50;
      // console.log('targetOffsetTop_________',targetOffsetTop)
      document.body.scrollTop = targetOffsetTop;
      document.documentElement.scrollTop = targetOffsetTop;
    },
  },
  destroy() {
    window.removeEventListener("scroll", this.onScroll);
  },
};
</script>

<style scoped lang="scss">
.sj_navbar {
  background: rgba(255, 255, 255, 0);
  color: #ffffff;
  transition: all 0.6s;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 999;

  &.header_fixed {
    color: #000000;
    background: rgba(255, 255, 255, 1);
    box-shadow: 0px 10px 10px rgba(0, 0, 0, 0.1);

    .sj_header {
      .header_logo {
        background-image: url("../assets/sj_logo_blue.png");
      }

      .header_nav {
        .navs {
          .nav_item {
            &.active {
              color: #3981f5;
            }
          }
        }
      }

      .header_right {
        span {
          color: rgba(0, 0, 0, 0.6);
        }
      }

      .header_mobile {
        .el-icon-menu {
          color: #3981f5;
        }
      }
    }
  }

  .sj_header {
    height: 100%;
    display: flex;
    align-items: center;

    .header_logo {
      cursor: pointer;
      min-width: 400px;
      height: 60px;
      background-image: url("../assets/sj_logo.png");
      background-repeat: no-repeat;
      background-position: center left;
      background-size: auto 30px;
    }

    .header_nav {
      flex: 1;

      .navs {
        @extend .flex;

        .nav_item {
          line-height: 60px;
          padding: 0 20px;
          cursor: pointer;
          position: relative;

          .nav_item_bar {
            height: 4px;
            background: #3981f5;
            opacity: 0;
            position: absolute;
            bottom: 0;
            left: 20px;
            right: 20px;
            transition: all 0.2s;
          }

          &.active {
            color: #ffffff;

            .nav_item_bar {
              opacity: 1;
            }
          }
        }
      }
    }

    .header_right {
      //   font-size: $fontsize_sm;
      //   span {
      //     color: rgba(255, 255, 255, 0.6);
      //     margin-left: 20px;
      //   }
      // 新增功能
      .nav_function {
        display: flex;
        align-items: center;

        .nav_fun_bar {
          cursor: pointer;
          display: flex;
          // flex-direction: column;
          align-items: center;
          margin: 5px 10px 0 10px;

          img {
            width: 40px;
            height: 40px;
          }

          span {
            width: 28px;
            margin-left: 8px;
            font-size: 14px;
            font-family: PingFang SC;
            font-weight: 500;
            line-height: 20px;
            color: #ffffff;
          }
        }
      }
    }

    .header_mobile {
      padding: 20px;
      position: absolute;
      top: 0;
      right: 0;

      .el-icon-menu {
        color: #ffffff;
        font-size: 20px;
      }
    }

    .mobile_navs {
      display: none;
      padding: 20px;
      height: 1px;
      background: rgba(0, 0, 0, 0.5);
      transition: all 0.5s ease-in;
      position: fixed;
      top: 60px;
      left: 0;
      right: 0;

      .nav_item {
        line-height: 40px;
        color: #ffffff;
        cursor: pointer;

        &.active {
          color: #3981f5;
        }
      }

      &.active {
        display: block;
        height: auto;
      }
    }
  }
}

.sj_carousel {
  position: relative;

  ::v-deep .el-image {
    height: 100%;
  }

  .sj_carousel_desc {
    position: absolute;
    bottom: 220px;
    left: 12%;
    color: #ffffff;

    .title {
      display: block;
      width: 240px;
      height: 46px;
    }

    .sj_line {
      margin: 50px 0;
    }

    .text {
      line-height: 24px;
      color: rgba(255, 255, 255, 0.6);
    }
  }

  .sj_mouse {
    width: 20px;
    height: 32px;
    background-image: url("../assets/icons/icon_mouse.png");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;
    position: absolute;
    bottom: 30px;
    left: 50%;
    margin-right: -10px;
  }
}

.img_box {
  position: relative;

  .version_item {
    position: absolute;
    margin: 74px 0 0 72px;
    z-index: 1;
    .imgLogo {
      width: 112px;
      height: 112px;
    }
    .logo_bottom {
      margin-bottom: 8px !important;
    }
    .version_info {
      width: 490px;
      position: absolute;
      p:first-child {
        padding-left: 6px;
        font-size: 24px;
        font-weight: bold;
        line-height: 48px;
        color: #000000;
      }
      p:last-child {
        font-size: 20px;
        font-weight: 400;
        line-height: 38px;
        color: #6a7483;
      }
    }
  }
	.version_item-mobile {
		position: absolute;
		width: 52%;
		margin: 5px 0 0 5px;
		font-size: 3.1vw;
		color: #6a7483;
		line-height: 1.2;
		z-index: 1;
		.imgBox {
			float:left;
			clear: both;
			margin-right: 5px;
		}
		.app_logo {
		  width: 12vw;
		  height: 12vw;
			margin-bottom: 0 !important;
		}
	}

  .el-image {
    cursor: pointer;
  }

  .box_desc {
    width: 265px;
    background-color: #ffffff;
    padding: 20px;
    position: absolute;
    top: 20px;
    left: 20px;
    bottom: 20px;
    cursor: pointer;

    &:hover {
      box-shadow: 0px 0px 20px rgba(57, 129, 245, 0.3);
    }

    .sj_line {
      margin: 30px 0;
    }

    .title {
      display: block;
      width: 138px;
    }

    .tags {
      &:nth-child(2n) {
        margin: 6px 0;
      }

      span {
        display: inline-block;
        padding: 3px 7px;
        color: $font_grey;
        font-size: $fontsize_sm;
        border: 1px solid rgba(148, 154, 163, 1);
        margin-right: 6px;
      }
    }

    .text {
      margin-top: 25px;
      color: $font_grey;
      font-size: $fontsize_sm;
    }
  }

  .box_desc_right {
    width: 410px;
    position: absolute;
    right: 20px;
    bottom: 20px;

    &.mobile {
      width: 100%;
      margin: 0 auto;
      position: relative;
      right: 0;
      left: 0;
    }

    .flex {
      height: 60px;
      justify-content: space-between;

      &.up {
        height: 70px;
        margin-bottom: 10px;
      }

      .link_up {
        width: 200px;
        height: 70px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100%;

        &.b1 {
          background-image: url("../assets/button/btn_1.png");
        }

        &.b2 {
          background-image: url("../assets/button/btn_2.png");
        }
      }

      .link {
        width: 60px;
        height: 60px;
        background-repeat: no-repeat;
        background-position: center center;
        background-size: 100%;

        &.b3 {
          background-image: url("../assets/button/btn_3.png");
        }

        &.b4 {
          background-image: url("../assets/button/btn_4.png");
        }

        &.b5 {
          background-image: url("../assets/button/btn_5.png");
        }

        &.b6 {
          background-image: url("../assets/button/btn_6.png");
        }

        &.b7 {
          background-image: url("../assets/button/btn_7.png");
        }

        &.b8 {
          background-image: url("../assets/button/btn_8.png");
        }
      }
    }
  }
}

.sj_card {
  .card_header {
    padding: 40px 0 40px;
    text-align: center;

    .title {
      height: 35px;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: auto 35px;

      &.a {
        background-image: url("../assets/home/home_a.png");
      }

      &.b {
        background-image: url("../assets/home/home_b.png");
      }

      &.c {
        background-image: url("../assets/home/home_c.png");
      }

      &.d {
        background-image: url("../assets/home/home_d.png");
      }

      &.e {
        background-image: url("../assets/home/home_e.png");
      }
    }

    .subtitle {
      font-size: $fontsize_base;
      color: $font_grey;
      padding-top: 10px;
    }
  }

  .card_body {
    .links {
      &.flex {
        align-items: stretch;
      }
    }

    .subtitle {
      height: 20px;
      background-repeat: no-repeat;
      background-position: 20px center;
      background-size: auto 100%;

      &.d1 {
        background-image: url("../assets/home/home_d_t1.png");
      }

      &.d2 {
        background-image: url("../assets/home/home_d_t2.png");
      }

      &.d3 {
        background-image: url("../assets/home/home_d_t3.png");
      }

      &.d4 {
        background-image: url("../assets/home/home_d_t4.png");
      }
    }

    .subtext {
      font-size: $fontsize_sm;
      color: $font_grey;
      padding-left: 20px;
    }

    ::v-deep .el-image {
      margin-bottom: 20px;
    }

    .img_laboratory {
      height: 30px;
      margin: 0 auto 30px;
    }
  }
}

.sj_contact {
  padding: 0;
  background: #eff2f5;

  .title {
    width: 120px;
    padding-top: 50px;
  }

  .info {
    dt {
      color: #000000;
      font-weight: bold;
      font-size: 18px;
      padding: 20px 0;
    }

    dd {
      color: #000000;
      line-height: 24px;
    }
  }
}

.btn_cooperation {
  display: block;
  width: 90px;
  margin: -30px auto 0;
  cursor: pointer;
}

@media (min-width: 1200px) {
  .sj_carousel {
    height: 560px;
  }

  .sj_card {
    .card_header {
      padding: 80px 0 40px;
    }

    .card_body {
      .img_laboratory {
        height: 100%;
        margin-bottom: 20px;
      }

      .links {
        &.flex {
          align-items: center;
        }
      }

      .subtitle {
        height: 26px;
        margin-bottom: 20px;
      }
    }
  }

  .btn_cooperation {
    display: block;
    width: 90px;
    margin: unset;
    margin-top: -15px;
  }

  .sj_contact {
    padding-top: 20px;

    .sj_card {
      .card_header {
        padding-bottom: 0;
      }
    }

    .title {
      padding-top: 130px;
    }

    // .info{
    /*padding-top: 50px;*/
    // }
  }
}
</style>
