<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>
<script>
export default {
  name: "app",
  components: {}
};
</script>

<style lang="scss">
  *{
    margin: 0;
    padding: 0;
    @extend .boxSizing;
  }

  ul,
  ol {
    list-style: none;
  }

  dl dt {
    font-weight: normal;
  }

  em,
  i {
    font-style: normal;
  }

  a {
    cursor: pointer;
    text-decoration: none;

    &:focus,
    &:hover {
      text-decoration: none;
    }
  }

  html {
    width: 100vw;
    height: 100vh;
    overflow-x: hidden!important;
    overflow-y: auto!important;
    /*scroll-behavior: smooth;*/
  }

  body {
    width: 100%;
    font-family: "Microsoft YaHei", "SimSun", Verdana, Geneva, sans-serif;
    font-size: $fontsize_base;
    color: $font_base;
    background: $bg_base;
    -webkit-font-smoothing: subpixel-antialiased;
    -moz-osx-font-smoothing: grayscale;
    position: relative;
    overflow-x: hidden!important;
    overflow-y: auto!important;
    // @extend .no_selected;
  }
  #app {
    .el-container {
      min-height: 100vh;
      .el-header,
      .el-main,
      .el-footer {
        padding: 0;
      }
      .el-main{
        overflow-x: hidden!important;
      }
      .el-footer {
        height: auto !important;
      }
    }
  }

</style>
