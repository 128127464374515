<template>
    <transition name="el-fade-in-linear">
        <el-container v-show="pageShow">
            <el-header class="sj_navbar_fixed">
                <Header :active="3"></Header>
            </el-header>
            <el-main class="sj_main">
                <div class="sj_container">
                    <div class="sj_breadcrumb">
                        <el-breadcrumb separator-class="el-icon-arrow-right">
                            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                            <el-breadcrumb-item>智慧病房</el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                    <el-row :gutter="50">
                        <el-col :md="24" :lg="12">
                            <img class="sj_img" src="../../assets/nursing/n_5.png"/>
                        </el-col>
                        <el-col :md="24" :lg="12">
                            <div class="sj_content">
                                <h2>智慧病房</h2>
                                <div class="sj_line"></div>
                                <p>智慧病房是基于计算机和通信技术的发展，应用软件开发、数据库、物联网、信息集成等技术，打破传统住院诊疗模式限制，以优化患者住院就医流程及病区管理流程为切入点，以实现“以患者为中心”的人性化住院诊疗服务模式为核心，以方便医患、确保医疗质量安全为目的，实现患者诊疗、护理等医疗业务及病区管理的数字化、集成化和智能化。</p>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="sj_content_title">
                        <h2 class="title">智慧病房整体解决方案</h2>
                        <div class="subtitle"><div class="sj_line"></div></div>
                    </div>
                    <div class="sj_content_img">
                        <el-image :src="listImg.src1">
                            <div slot="placeholder" class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                            <div slot="error" class="image_error">图片加载失败</div>
                        </el-image>
                    </div>
                    <div class="sj_content_title">
                        <h2 class="title">智慧病房系统分类</h2>
                        <div class="subtitle"><div class="sj_line"></div></div>
                    </div>
                    <div class="sj_content_img">
                        <el-image :src="listImg.src2">
                            <div slot="placeholder" class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                            <div slot="error" class="image_error">图片加载失败</div>
                        </el-image>
                    </div>
                    <div class="sj_content_title">
                        <h2 class="title">全院联网</h2>
                        <div class="subtitle">系统自有数据平台将HIS、LIS、PACS、CIS等系统进行数据集成，自动分发至<br/>各业务终端，拒绝信息孤岛。</div>
                        <div class="subtitle"><div class="sj_line"></div></div>
                    </div>
                    <div class="sj_content_img">
                        <el-image :src="listImg.src3">
                            <div slot="placeholder" class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                            <div slot="error" class="image_error">图片加载失败</div>
                        </el-image>
                    </div>
                    <div class="sj_content_title">
                        <h2 class="title">护士站智能交互系统</h2>
                        <div class="subtitle"><div class="sj_line"></div></div>
                    </div>
                    <div class="sj_content_img">
                        <el-image :src="listImg.src4">
                            <div slot="placeholder" class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                            <div slot="error" class="image_error">图片加载失败</div>
                        </el-image>
                        <el-image :src="listImg.src5">
                            <div slot="placeholder" class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                            <div slot="error" class="image_error">图片加载失败</div>
                        </el-image>
                    </div>
                    <div class="sj_content_title">
                        <h2 class="title">床旁智能交互系统</h2>
                        <div class="subtitle"><div class="sj_line"></div></div>
                    </div>
                    <div class="sj_content_img">
                        <el-image :src="listImg.src6">
                            <div slot="placeholder" class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                            <div slot="error" class="image_error">图片加载失败</div>
                        </el-image>
                        <el-image :src="listImg.src7">
                            <div slot="placeholder" class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                            <div slot="error" class="image_error">图片加载失败</div>
                        </el-image>
                        <el-image :src="listImg.src8">
                            <div slot="placeholder" class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                            <div slot="error" class="image_error">图片加载失败</div>
                        </el-image>
                    </div>
                    <div class="sj_content_title">
                        <h2 class="title">病房电子门牌</h2>
                        <div class="subtitle"><div class="sj_line"></div></div>
                    </div>
                    <div class="sj_content_img">
                        <el-image :src="listImg.src9" @load="handleImg" @error="handleError">
                            <div slot="placeholder" class="image-slot">
                                加载中<span class="dot">...</span>
                            </div>
                            <div slot="error" class="image_error">图片加载失败</div>
                        </el-image>
                    </div>
                </div>
            </el-main>
            <el-footer>
                <Footer></Footer>
            </el-footer>
        </el-container>
    </transition>
</template>

<script>
  import Header from "../../components/Header";
  import Footer from "../../components/Footer";

  export default {
    components: {
      Header,
      Footer
    },
    data() {
      return {
        pageShow:false,
        listImg:{
          src1:require('../../assets/nursing/n_1_1.png')
          ,src2:require('../../assets/nursing/n_1_2.png')
          ,src3:require('../../assets/nursing/n_1_3.png')
          ,src4:require('../../assets/nursing/n_1_4.png')
          ,src5:require('../../assets/nursing/n_1_5.png')
          ,src6:require('../../assets/nursing/n_1_6.png')
          ,src7:require('../../assets/nursing/n_1_7.png')
          ,src8:require('../../assets/nursing/n_1_8.png')
          ,src9:require('../../assets/nursing/n_1_9.png')
        }
      };
    },
    methods:{
      handleImg() {
        this.pageShow = true;
      },
      handleError(){
        this.pageShow = true;
      }
    }
  };
</script>

<style scoped lang="scss">
    .sj_line {
        margin-top: 20px;
    }
</style>
