<template>
  <transition name="el-fade-in-linear">
    <el-container v-show="pageShow">
      <el-header class="sj_navbar_fixed">
        <Header :active="1"></Header>
      </el-header>
      <el-main class="sj_main">
        <div class="sj_container">
          <div class="sj_breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>智慧型内科技能训练室</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <el-row :gutter="50">
            <el-col :md="24" :lg="12">
              <el-image class="sj_img" :src="img" @load="handleImg" @error="handleError">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
                <div slot="error" class="image_error">图片加载失败</div>
              </el-image>
            </el-col>
            <el-col :md="24" :lg="12">
              <div class="sj_content">
                <h2>智慧型内科技能训练室</h2>
                <div class="sj_line"></div>
                <p>针对临床内科教学和技能操作训练特点，对内科教室和内科训练室进行整体规划设计或者原环境的智能化升级改造。设计师现场测量，3D建模。方案设计将融合教学模式、教学内涵、教学技术和教学环境等四个方面要素，建立内科教学大数据智能教学平台，面向内科教学和训练全过程构建基于课程知识图谱的形成性评价体系。支撑开展胸腔穿刺术、腹腔穿刺术、骨髓穿刺术、腰椎穿刺术等穿刺、心肺听诊和体格检查等内科技能训练。是医院和医学院首选的内科教学、示教、训练、考核、管理以及评价的智慧型内科技能训练室一体化方案。</p>
                <p>方案不仅仅提供了训练所需的模型人、操作台、网络、智能黑板（电脑投影仪）、门禁、智能门牌等基础训练和信息化设施，还可以开展在线学习、实训室开放预约、在线训仿真实训室、同伴互评、边看边练、投屏示教等教学模式的开展，此外更提供内科相关课程的O2O立体讲义、学习资源和教学文化。</p>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-main>
      <el-footer>
        <Footer></Footer>
      </el-footer>
    </el-container>
  </transition>
</template>

<script>
  import Header from '../../components/Header'
  import Footer from '../../components/Footer'

  export default {
    components: {
      Header,
      Footer
    },
    data(){
      return{
        pageShow:false,
        img:require("../../assets/medical/medical_7.jpg")
      }
    },
    methods:{
      handleImg() {
        this.pageShow = true;
      },
      handleError(){
        this.pageShow = true;
      }
    }
  }
</script>

<style scoped lang="scss">
  .sj_content{
    div,p{
      font-size: $fontsize_base;
      color: #6A7483;
    }
    p{
      margin: 40px 0;
      text-align: justify;
    }
    dl{
      float: left;
      margin-right: 50px;
      dd{
        padding: 4px 0;
      }
    }
  }
  .sj_line{
    margin-top: 20px;
  }
</style>
