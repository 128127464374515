<template>
    <transition name="el-fade-in-linear">
        <el-container v-show="pageShow">
            <el-header class="sj_navbar_fixed">
                <Header :active="3"></Header>
            </el-header>
            <el-main class="sj_main">
                <div class="sj_container">
                    <div class="sj_breadcrumb">
                        <el-breadcrumb separator-class="el-icon-arrow-right">
                            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                            <el-breadcrumb-item>智慧护理一体化信息平台</el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                    <el-row :gutter="50">
                        <el-col :md="24" :lg="12">
                            <el-image class="sj_img" :src="img" @load="handleImg" @error="handleError">
                                <div slot="placeholder" class="image-slot">
                                    加载中<span class="dot">...</span>
                                </div>
                                <div slot="error" class="image_error">图片加载失败</div>
                            </el-image>
                            <!--<img class="sj_img" src="../../assets/nursing/n_5.png"/>-->
                        </el-col>
                        <el-col :md="24" :lg="12">
                            <div class="sj_content">
                                <h2>智慧护理一体化信息平台</h2>
                                <div class="sj_line"></div>
                                <p>基于数据一体化的院前、院中和院后全流程智能护理服务平台的建设，实现全流程护理服务质量的控制，提升工作效率，为患者提供安全有效的护理服务，为护理工作者提供科学便捷的智能方法与工具。</p>
                                <p>梳理护理相关工作的内容和流程，依托5G、云计算大数据、物联网、人工智能和互联网+等新兴技术的应用创新，构建基于数据一体化的全流程智能护理服务平台。</p>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-main>
            <el-footer>
                <Footer></Footer>
            </el-footer>
        </el-container>
    </transition>
</template>

<script>
  import Header from "../../components/Header";
  import Footer from "../../components/Footer";

  export default {
    components: {
      Header,
      Footer
    },
    data() {
      return {
        pageShow:false,
        img:require('../../assets/nursing/n_5.png')
      };
    },
    methods:{
      handleImg() {
        this.pageShow = true;
      },
      handleError(){
        this.pageShow = true;
      }
    }
  };
</script>

<style scoped lang="scss">
    .sj_line {
        margin-top: 20px;
    }
</style>
