<template>
  <transition name="el-fade-in-linear">
    <el-container v-show="pageShow">
      <el-header class="sj_navbar_fixed">
        <Header :active="1"></Header>
      </el-header>
      <el-main class="sj_main">
        <div class="sj_container">
          <div class="sj_breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>尚哲心肺复苏模拟人</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <el-row :gutter="50">
            <el-col :md="24" :lg="12">
              <el-image class="sj_img" :src="img" @load="handleImg" @error="handleError">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
                <div slot="error" class="image_error">图片加载失败</div>
              </el-image>
            </el-col>
            <el-col :md="24" :lg="12">
              <div class="sj_content">
                <h2>尚哲心肺复苏模拟人</h2>
                <div class="sj_line"></div>
                <p>● 模型自带仿真伤情和应激反应 表现。产品可遥控操作断肢腿部活动，可实现断肢腿 部因条件反射下造成的断肢不自主的强烈应激反射运 动及模拟动脉射血，并可同步匹配模拟伤员的呼痛语音。腿部可自主剧烈运动应激反应及伤员语音，使创 伤救护场景更加真实。并提供智能检测。</p>
                <p>● 全身模拟人，四肢完整，男性，与真人1:1比例，体内带有全身仿生骨骼结构，体现各部位真实的骨性 标志，骨骼各连接部位为全钢材质，确保牢固耐用。</p>
                <p>● 颈部、双侧肩关节、肘关节、腕关节、髋关节、膝 关节、踝关节可以活动，实现多种体位变换。</p>
                <p>● 模型的下肢连接部位可徒手安装、拆卸。</p>
                <p>● 可模拟多部位、多致伤因素导致的伤情，可真实流血，可远程控制出血部位。</p>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-main>
      <el-footer>
        <Footer></Footer>
      </el-footer>
    </el-container>
  </transition>
</template>

<script>
  import Header from '../../components/Header'
  import Footer from '../../components/Footer'

  export default {
    components: {
      Header,
      Footer
    },
    data(){
      return{
        pageShow:false,
        img:require("../../assets/medical/medical_777.png")
      }
    },
    methods:{
      handleImg() {
        this.pageShow = true;
      },
      handleError(){
        this.pageShow = true;
      }
    }
  }
</script>

<style scoped lang="scss">
  .sj_content{
    div,p{
      font-size: $fontsize_base;
      color: #6A7483;
    }
    p{
      margin: 40px 0;
      text-align: justify;
    }
    dl{
      float: left;
      margin-right: 50px;
      dd{
        padding: 4px 0;
      }
    }
  }
  .sj_line{
    margin-top: 20px;
  }
</style>
