<template>
  <transition name="el-fade-in-linear">
    <el-container v-show="pageShow">
      <el-header class="sj_navbar_fixed">
        <Header :active="1"></Header>
      </el-header>
      <el-main class="sj_main">
        <div class="sj_container">
          <div class="sj_breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>做方案 找尚哲</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <el-row :gutter="50">
            <el-col :md="24" :lg="12">
              <el-image class="sj_img" :src="img" @load="handleImg" @error="handleError">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
                <div slot="error" class="image_error">图片加载失败</div>
              </el-image>
            </el-col>
            <el-col :md="24" :lg="12">
              <div class="sj_content">
                <h2>做方案 找尚哲</h2>
                <div class="sj_line"></div>
                <p>智慧医教顶级设计公司，100+设计案例！</p>
                <p>尚哲智能汇聚智慧医教方案设计精英，服务医院、医学院、医专及卫校用户，专业定制技能中心、住培基地智能化改造方案，按照4.0标准，运用人工智能、大数据、虚拟仿真、知识图谱、高端模拟人等技术，大幅度提升教学效果。</p>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-main>
      <el-footer>
        <Footer></Footer>
      </el-footer>
    </el-container>
  </transition>
</template>

<script>
  import Header from '../../components/Header'
  import Footer from '../../components/Footer'

  export default {
    components: {
      Header,
      Footer
    },
    data(){
      return{
        pageShow:false,
        img:require("../../assets/medical/medical_4.jpg")
      }
    },
    methods:{
      handleImg() {
        this.pageShow = true;
      },
      handleError(){
        this.pageShow = true;
      }
    }
  }
</script>

<style scoped lang="scss">
  .sj_content{
    div,p{
      font-size: $fontsize_base;
      color: #6A7483;
    }
    p{
      margin: 40px 0;
    }
    dl{
      float: left;
      margin-right: 50px;
      dd{
        padding: 4px 0;
      }
    }
  }
  .sj_line{
    margin-top: 20px;
  }
</style>
