<template>
    <el-container>
        <el-header class="sj_navbar_fixed">
            <Header></Header>
        </el-header>
        <el-main class="sj_main">
            <div class="sj_container">
                <div class="sj_breadcrumb">
                    <el-breadcrumb separator-class="el-icon-arrow-right">
                        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                        <el-breadcrumb-item>合作申请</el-breadcrumb-item>
                    </el-breadcrumb>
                </div>
                <el-row :gutter="100">
                    <el-col :md="24" :lg="12">
                        <el-form class="sj_form" :rules="rules" ref="formInfo" :model="formInfo">
                            <el-row :gutter="15">
                                <el-col :xs="24" :sm="24">
                                    <el-form-item prop="company">
                                        <el-input v-model="formInfo.company" placeholder="您所在的单位名称" clearable></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :xs="24" :sm="12">
                                    <el-form-item prop="name">
                                        <el-input v-model="formInfo.name" placeholder="姓名" clearable></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :xs="24" :sm="12">
                                    <el-form-item>
                                        <el-input v-model="formInfo.job" placeholder="职务" clearable></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :xs="24" :sm="12">
                                    <el-form-item prop="phone">
                                        <el-input v-model="formInfo.phone" placeholder="手机号" clearable></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :xs="24" :sm="12">
                                    <el-form-item>
                                        <el-input v-model="formInfo.mail" placeholder="邮箱" clearable></el-input>
                                    </el-form-item>
                                </el-col>
                                <el-col :xs="24" :sm="24">
                                    <el-button type="primary" @click="onSubmit('formInfo')">立即提交</el-button>
                                </el-col>
                            </el-row>
                        </el-form>
                    </el-col>
                    <el-col :md="24" :lg="12">
                        <div class="sj_content">
                            <h2>留下您的联系方式<br/>我们将在一个工作日内与您联系</h2>
                            <div class="sj_line"></div>
                            <p>期待与您进行一次有效的沟通，<br/>认真倾听您的需求、提供有价值的解决方案。</p>
                        </div>
                    </el-col>
                </el-row>
            </div>
        </el-main>
        <el-footer>
            <Footer></Footer>
        </el-footer>
    </el-container>
</template>

<script>
  import Header from "../../components/Header";
  import Footer from "../../components/Footer";

  export default {
    components: {
      Header,
      Footer
    },
    data() {
      return {
        type: "",
        formInfo:{
          company:'',
          name:'',
          job:'',
          phone:'',
          mail:''
        },
        rules: {
          company: [
            { required: true, message: '请输入您所在的单位名称', trigger: 'blur' }
          ],
          name: [
            { required: true, message: '请输入您的姓名', trigger: 'blur' }
          ],
          phone: [
            { required: true, message: '请输入您的手机号', trigger: 'blur' }
          ]
        }
      };
    },
    mounted() {

    },

    methods: {
      onSubmit(formName) {
        const that=this
        that.$refs[formName].validate((valid) => {
          if (valid) {
            that.$ajax.post(that.$api.customerInfo,that.formInfo).then(res=>{
              that.$message({
                message: '提交成功',
                type: 'success',
                duration:1500,
                onClose(){
                  that.$router.push({
                    name: 'home'
                  })
                }
              })

            }).catch(function (err) {
              window.console.log(err)
            })

          } else {
            window.console.log('error submit!!');
            return false;
          }
        });
      }
    }
  };
</script>

<style scoped lang="scss">
    .sj_form{
        ::v-deep.el-input__inner{
            border: none;
            background-color: #F7F7F7;
            border-radius: 0;
        }
        ::v-deep.el-button{
            border-radius: 0;
            background-color: #3981F5;
            border-color: #3981F5;
        }
    }
    .sj_line {
        margin-top: 20px;
    }

</style>
