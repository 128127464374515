<template>
    <transition name="el-fade-in-linear">
        <el-container v-show="pageShow">
            <el-header class="sj_navbar_fixed">
                <Header :active="2"></Header>
            </el-header>
            <el-main class="sj_main">
                <div class="sj_container">
                    <div class="sj_breadcrumb">
                        <el-breadcrumb separator-class="el-icon-arrow-right">
                            <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
                            <el-breadcrumb-item>阶梯智慧教室</el-breadcrumb-item>
                        </el-breadcrumb>
                    </div>
                    <el-row :gutter="50">
                        <el-col :md="24" :lg="12">
                            <img class="sj_img" src="../../assets/class/class_5.jpg"/>
                        </el-col>
                        <el-col :md="24" :lg="12">
                            <div class="sj_content">
                                <h2>阶梯智慧教室</h2>
                                <div class="sj_line"></div>
                                <p>尚哲阶梯智慧教室解决方案，针对学校大课、演示报告、成果展示场景，对教室进行阶梯布局空间设计，通过多媒体、物联设备、互动教学系统、录播系统、音响系统、网络的应用，提供远程录播、多屏互动、课件分屏、物联一屏管控、教学数据分析等功能，使教师教学更有效，学生课堂学习更高效。</p>
                            </div>
                        </el-col>
                    </el-row>
                    <div class="sj_content_title">
                        <h2 class="title">更多类型</h2>
                        <div class="subtitle"><div class="sj_line"></div></div>
                    </div>
                    <el-row :gutter="30">
                        <el-col :xs="24" :md="12" :lg="8" v-for="(item,index) in listClass" :key="index">
                            <div class="sj_content_more" @click="jump(item.router)">
                                <el-image class="sj_img" :src="item.src" @load="handleImg" @error="handleError">
                                    <div slot="placeholder" class="image-slot">
                                        加载中<span class="dot">...</span>
                                    </div>
                                    <div slot="error" class="image_error">图片加载失败</div>
                                </el-image>
                                <div class="title">{{item.name}}</div>
                            </div>
                        </el-col>
                    </el-row>
                </div>
            </el-main>
            <el-footer>
                <Footer></Footer>
            </el-footer>
        </el-container>
    </transition>
</template>

<script>
  import Header from "../../components/Header";
  import Footer from "../../components/Footer";

  export default {
    components: {
      Header,
      Footer
    },
    data() {
      return {
        type: "",
        pageShow: false,
        listClass:[
          {src:require('../../assets/class/class_sm_7.jpg'),name:'标准智慧教室',router:'c7'}
          ,{src:require('../../assets/class/class_sm_1.jpg'),name:'TBL智慧教室',router:'c1'}
          ,{src:require('../../assets/class/class_sm_2.jpg'),name:'PBL智慧教室',router:'c2'}
          ,{src:require('../../assets/class/class_sm_3.jpg'),name:'微格智慧教室',router:'c3'}
          ,{src:require('../../assets/class/class_sm_4.jpg'),name:'电教智慧教室',router:'c4'}
          ,{src:require('../../assets/class/class_sm_6.jpg'),name:'工业机器人智慧实训基地',router:'c6'}
        ]
      };
    },
    mounted() {

    },
    methods:{
      //跳转页面
      jump(name) {
        this.$nextTick(function () {
          this.$router.push({ name: name })
        })
      },
      handleImg() {
        this.pageShow = true;
      },
      handleError(){
        this.pageShow = true;
      }
    }
  };
</script>

<style scoped lang="scss">
    .sj_line {
        margin-top: 20px;
    }
    .sj_content_more{
        cursor: pointer;
        margin-bottom: 30px;
        .title{
            line-height: 50px;
            background: #376ACB;
            color: #ffffff;
            font-size: $fontsize_base;
            text-indent: 20px;
        }
    }
</style>
