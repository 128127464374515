import axios from 'axios';

//baseURL
axios.defaults.baseURL=process.env.NODE_ENV==='production'?process.env.VUE_APP_URL:'';
axios.defaults.headers.post['Content-Type'] = 'application/json;charset=UTF-8';

// 请求拦截
axios.interceptors.request.use(config => {
    // 请求前最后的配置,可以加入用户授权信息
    return config
}, error => {
    return Promise.reject(error)
})

// 响应拦截
axios.interceptors.response.use(response => {
    // 请求成功,定制拦截,返回数据
    return response;
}, error => {
    // 请求失败
    if (error && error.response) {
        switch (error.response.status) {
            case 400:
                // 400错误处理
                break
            case 401:
                // 401错误处理
                break
            default:
                // 默认处理
                return Promise.reject(error);
        }
    }

})
