<template>
  <transition name="el-fade-in-linear">
    <el-container v-show="pageShow">
      <el-header class="sj_navbar_fixed">
        <Header :active="1"></Header>
      </el-header>
      <el-main class="sj_main">
        <div class="sj_container">
          <div class="sj_breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>智慧医教综合一体化信息平台</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <el-row :gutter="50">
            <el-col :md="24" :lg="12">
              <el-image class="sj_img" :src="img" @load="handleImg" @error="handleError">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
                <div slot="error" class="image_error">图片加载失败</div>
              </el-image>
            </el-col>
            <el-col :md="24" :lg="12">
              <div class="sj_content">
                <h2>智慧医教综合一体化信息平台</h2>
                <div class="sj_line"></div>
                <p>尚哲智能智慧医教综合一体化信息平台，是专门面向智慧医学教育领域推出的软件平台产品，提供了全新的“教学练考管评”一体化教育模式。产品结合课堂互动教学、学生自主学习、翻转课堂、O2O线上线下结合讲义、在线虚拟仿真教学、无人值守开放预约练习、示教反示教、大数据可视化管理等先进的教学理念，以千人千面的知识图谱为抓手，对教学工作全面实现信息化管理，将教学管理人员从繁杂的日常教学管理工作中解脱出来，并可使学习者相对自由地完成学习任务，从而大幅提升教学效果。</p>
                <div class="clear">
                  <dl>
                    <dd>•  智慧教室</dd>
                    <dd>•  电教室</dd>
                    <dd>•  在线自主学习</dd>
                    <dd>•  在线虚拟仿真训练</dd>
                    <dd>•  边看边练</dd>
                    <dd>•  同伴互助</dd>
                    <dd>•  在线理论考试</dd>
                    <dd>•  OSCE</dd>
                  </dl>
                  <dl>
                    <dd>•  管理者驾驶舱</dd>
                    <dd>•  无人值守预约</dd>
                    <dd>•  考勤</dd>
                    <dd>•  形成性评价</dd>
                    <dd>•  大数据</dd>
                    <dd>•  知识图谱</dd>
                  </dl>
                  <dl>
                    <dd>•  统一账号</dd>
                    <dd>•  独立门户</dd>
                    <dd>•  专属APP</dd>
                  </dl>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-main>
      <el-footer>
        <Footer></Footer>
      </el-footer>
    </el-container>
  </transition>
</template>

<script>
  import Header from '../../components/Header'
  import Footer from '../../components/Footer'

  export default {
    components: {
      Header,
      Footer
    },
    data(){
      return{
        pageShow:false,
        img:require("../../assets/medical/medical_1.jpg")
      }
    },
    methods:{
      handleImg() {
        this.pageShow = true;
      },
      handleError(){
        this.pageShow = true;
      }
    }
  }
</script>

<style scoped lang="scss">
  .sj_content{
    div,p{
      font-size: $fontsize_base;
      color: #6A7483;
    }
    dl{
      float: left;
      margin-right: 50px;
      dd{
        padding: 4px 0;
      }
    }
  }
  .sj_line{
    margin-top: 20px;
  }
</style>
