import Vue from "vue";
import Router from "vue-router";
import Home from "@/views/Home.vue";
import Sam from "@/views/intelligence/sam";
import Jan from "@/views/intelligence/jan";
import Cooperation from "@/views/intelligence/cooperation";
import M1 from "@/views/medical/p1";
import M2 from "@/views/medical/p2";
import M3 from "@/views/medical/p3";
import M4 from "@/views/medical/p4";
import M5 from "@/views/medical/p5";
import M6 from "@/views/medical/p6";
import M7 from "@/views/medical/p7";
import M8 from "@/views/medical/p8";
import M9 from "@/views/medical/p9";
import M10 from "@/views/medical/p10";
import M11 from "@/views/medical/p11";
import M12 from "@/views/medical/p12";
import M666 from "@/views/medical/p666";
import M777 from "@/views/medical/p777";
import M888 from "@/views/medical/p888";
import C1 from "@/views/class/p1";
import C2 from "@/views/class/p2";
import C3 from "@/views/class/p3";
import C4 from "@/views/class/p4";
import C5 from "@/views/class/p5";
import C6 from "@/views/class/p6";
import C7 from "@/views/class/p7";
import N1 from "@/views/nursing/p1";
import N2 from "@/views/nursing/p2";
import N3 from "@/views/nursing/p3";
import N4 from "@/views/nursing/p4";
import N5 from "@/views/nursing/p5";
Vue.use(Router);
// const base = process.env.NODE_ENV === 'development' ? '/' : '/site/'
export default new Router({
  // mode: 'history',
  routes: [
    
    {
      path: "/",
      name: "home",
      component: Home
    }
    ,{
      path: "/intelligence/sam",
      name: "sam",
      component: Sam
    }
    ,{
      path: "/intelligence/jan",
      name: "jan",
      component: Jan
    }
    ,{
      path: "/intelligence/cooperation",
      name: "cooperation",
      component: Cooperation
    }
    ,{
      path: "/medical/p1",
      name: "m1",
      component: M1
    }
    ,{
      path: "/medical/p2",
      name: "m2",
      component: M2
    }
    ,{
      path: "/medical/p3",
      name: "m3",
      component: M3
    }
    ,{
      path: "/medical/p4",
      name: "m4",
      component: M4
    }
    ,{
      path: "/medical/p5",
      name: "m5",
      component: M5
    }
    ,{
      path: "/medical/p6",
      name: "m6",
      component: M6
    }
    ,{
      path: "/medical/p7",
      name: "m7",
      component: M7
    }
    ,{
      path: "/medical/p8",
      name: "m8",
      component: M8
    },
		{
			path: "/medical/p666",
			name: "m666",
			component: M666
		},
		{
			path: "/medical/p777",
			name: "m777",
			component: M777
		},
		{
			path: "/medical/p888",
			name: "m888",
			component: M888
		},
    ,{
      path: "/medical/p9",
      name: "m9",
      component: M9
    }
    ,{
      path: "/medical/p10",
      name: "m10",
      component: M10
    }
    ,{
      path: "/medical/p11",
      name: "m11",
      component: M11
    }
    ,{
      path: "/medical/p12",
      name: "m12",
      component: M12
    }
    ,{
      path: "/class/p1",
      name: "c1",
      component: C1
    }
    ,{
      path: "/class/p2",
      name: "c2",
      component: C2
    }
    ,{
      path: "/class/p3",
      name: "c3",
      component: C3
    }
    ,{
      path: "/class/p4",
      name: "c4",
      component: C4
    }
    ,{
      path: "/class/p5",
      name: "c5",
      component: C5
    }
    ,{
      path: "/class/p6",
      name: "c6",
      component: C6
    }
    ,{
      path: "/class/p7",
      name: "c7",
      component: C7
    }
    ,{
      path: "/nursing/p1",
      name: "n1",
      component: N1
    }
    ,{
      path: "/nursing/p2",
      name: "n2",
      component: N2
    }
    ,{
      path: "/nursing/p3",
      name: "n3",
      component: N3
    }
    ,{
      path: "/nursing/p4",
      name: "n4",
      component: N4
    }
    ,{
      path: "/nursing/p5",
      name: "n5",
      component: N5
    },
   
    
  ]
});


