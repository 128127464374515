<template>
  <transition name="el-fade-in-linear">
    <el-container v-show="pageShow">
      <el-header class="sj_navbar_fixed">
        <Header :active="1"></Header>
      </el-header>
      <el-main class="sj_main">
        <div class="sj_container">
          <div class="sj_breadcrumb">
            <el-breadcrumb separator-class="el-icon-arrow-right">
              <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
              <el-breadcrumb-item>医学课程资源</el-breadcrumb-item>
            </el-breadcrumb>
          </div>
          <el-row :gutter="50">
            <el-col :md="24" :lg="12">
              <el-image class="sj_img" :src="img" @load="handleImg" @error="handleError">
                <div slot="placeholder" class="image-slot">
                  加载中<span class="dot">...</span>
                </div>
                <div slot="error" class="image_error">图片加载失败</div>
              </el-image>
            </el-col>
            <el-col :md="24" :lg="12">
              <div class="sj_content">
                <h2>医学课程资源</h2>
                <div class="sj_line"></div>
                <p>尚哲智能推出的优质医学课程资源，实现了教学全流程的助教、助学、助练的功能。配合智课APP，可以随时随地的学习；还可以通过APP端的“扫一扫”功能扫描O2O立体讲义中的二维码获取全媒体教学资源，包括真人视频、动画、课件、试题等；</p>
                <p>结合知识图谱，能够实现千人千面的评价模型，让教、学、管理全流程实现数字化管理，变得更简单、更轻松、更系统、更高效。</p>
                <p>提供以下课程配套的资源、O2O立体讲义及知识图谱:</p>
                <div class="clear">
                  <dl>
                    <dd>《诊断学》</dd>
                    <dd>《内科学》</dd>
                    <dd>《外科学》</dd>
                    <dd>《妇产科学》</dd>
                    <dd>《生理学》</dd>
                    <dd>《儿科学》</dd>
                    <dd>《系统解剖学》</dd>
                    <dd>《急诊与灾难医学》</dd>
                  </dl>
                  <dl>
                    <dd>《基础护理学》</dd>
                    <dd>《健康评估》</dd>
                    <dd>《内科护理学》</dd>
                    <dd>《外科护理学》</dd>
                    <dd>《妇产科护理学》</dd>
                    <dd>《儿科护理学》</dd>
                    <dd>《老年护理学》</dd>
                    <dd>《急危重症护理学》</dd>
                  </dl>
                </div>
              </div>
            </el-col>
          </el-row>
        </div>
      </el-main>
      <el-footer>
        <Footer></Footer>
      </el-footer>
    </el-container>
  </transition>
</template>

<script>
  import Header from '../../components/Header'
  import Footer from '../../components/Footer'

  export default {
    components: {
      Header,
      Footer
    },
    data(){
      return{
        pageShow:false,
        img:require("../../assets/medical/medical_2.jpg")
      }
    },
    methods:{
      handleImg() {
        this.pageShow = true;
      },
      handleError(){
        this.pageShow = true;
      }
    }
  }
</script>

<style scoped lang="scss">
  .sj_content{
    div,p{
      font-size: $fontsize_base;
      color: #6A7483;
    }
    p{
      margin: 40px 0;
    }
    dl{
      float: left;
      margin-right: 50px;
      dd{
        padding: 4px 0;
      }
    }
  }
  .sj_line{
    margin-top: 20px;
  }
</style>
