import { render, staticRenderFns } from "./sam.vue?vue&type=template&id=6ff5d317&scoped=true&"
import script from "./sam.vue?vue&type=script&lang=js&"
export * from "./sam.vue?vue&type=script&lang=js&"
import style0 from "./sam.vue?vue&type=style&index=0&id=6ff5d317&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6ff5d317",
  null
  
)

export default component.exports