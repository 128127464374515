<template>
    <div class="sj_footer">
        <div class="sj_container">
            <div class="declare">
                <div class="title">商标申明</div>
                <div class="detail"><i class="icon_logo"></i>、<strong>SAMJAN</strong>、<strong>尚哲智能</strong>、<strong>尚哲医护</strong>、<strong>尚哲智课</strong>  是南京尚哲智能科技有限公司的商标或者注册商标。在本网站中以及本网站描述的产品中，出现的其他商标、产品名称、服务名称以及公司名称，由其各自的所有人拥有。</div>
            </div>
            <div class="copyright">Copyright© 2017-2020 南京尚哲智能科技有限公司 . All right reserved.<br/><a href="https://beian.miit.gov.cn" target="_blank"><a href="https://beian.miit.gov.cn" target="_blank">苏ICP备17074629号-2</a></a></div>
        </div>
    </div>
</template>

<script>
  export default {
    name: "Footer"
  };
</script>

<style lang="scss" scoped>
    .sj_footer{
        background: #262D3C;
        .sj_container{
            display: -webkit-box;
            display: -webkit-flex;
            display: -moz-box;
            display: -moz-flex;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-direction: reverse;
            -webkit-box-orient: vertical;
            -webkit-flex-direction: column;
            -moz-flex-direction: column;
            -ms-flex-direction: column;
            flex-direction: column;
            height: 100%;
            padding: 50px 20px;
            color: $font_grey;
            font-size: $fontsize_sm;
            .declare{
                width: 100%;
                max-width: 480px;
                margin-bottom: 25px;
                .title{
                    font-size: $fontsize_md;
                    color: #ffffff;
                    padding-bottom: 25px;
                }
            }
        }
        .icon_logo{
            display: inline-block;
            width: 15px;
            height: 18px;
            vertical-align: baseline;
            background-image: url("../assets/icons/icon_logo.png");
            background-repeat: no-repeat;
            background-size: contain;
            background-position: center left;
        }

    }
    @media (min-width: 1200px){
        .sj_footer{
            .sj_container {
                -webkit-box-direction: normal;
                -webkit-box-orient: horizontal;
                -webkit-flex-direction: row;
                -moz-flex-direction: row;
                -ms-flex-direction: row;
                flex-direction: row;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                justify-content: space-between;
                padding: 50px 0;
                .declare{
                    width: 40%;
                    max-width: 480px;
                }
                .copyright{
                    margin-top: 15px;
                    a{
                        color: $font_grey;
                        &:hover{
                            color: #3981F5;
                        }
                    }
                }
            }
        }

    }
</style>
